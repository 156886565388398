import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
    tripAndTravellerDetails: {},
    pendingTrips:[],
    oneWayTrips:[],
    userSelectedTrip:null,
    seats:[],
    oneWayBookingMinutesTimer : 0,
    oneWayBookingShowTimer : false,
    oneWayTotalAmount:0,
    selectedInsuranceProvider:{},
    insuranceProvider:null,
    selectedInsurancePlan:null,
    selectedInsuranceAmount: 0,
    couponDetail:null,
    nrcTrainTrips:[],
    userSelectedNrcTrip:null,
    nrcTrainTripSeats:[]
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
