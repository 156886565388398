/* eslint-disable no-unused-vars */
import callEndpoint from "@/services/api";
import METHODS from "@/services/api/apiMethods";
import axios from "axios";
import authHeader from "@/services/api/auth-header";
import {
  data
} from "autoprefixer";
import dayjs from "dayjs";

let RESOURCE_URL = "";

if (process.env.NODE_ENV === "development") {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
} else {
  RESOURCE_URL = process.env.VUE_APP_DEV_AUTH_SERVER_URL;
}
const {
  POST,
  //     PUT,
  PATCH,
  DELETE,
} = METHODS;

export const uploadToCloudinary = async (data) => {

  return axios.post(process.env.VUE_APP_CLOUDINARY_URL, data);
};
// https://res.cloudinary.com/demo/image/upload/f_auto,q_auto,e_trim,b_white/bo_1px_solid_gray/docs/rmv_bgd/stuffed.jpg
// Get countries and their states
const COUNTRY_BASE_URL = "https://countriesnow.space/api/v0.1/countries/states";

const CITY_BASE_URL =
  "https://countriesnow.space/api/v0.1/countries/state/cities";

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

const ysgHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjbGllbnRJRCI6Imh0dHBzOlwvXC93d3cubnBvZC5uZyIsImNsaWVudFNlY3JldCI6InZhQFZtNSFpb3AifQ.dqwQ1bSt1FDd9N2NpSnYuoF9qt1NLsETIM6_Ggissms'
}

const getKudaJwtToken = () => {
  return sessionStorage.getItem('kudaJwt')
}

const header = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + getKudaJwtToken(),
}

export const getAllCountries = async () => {
  return axios.get(COUNTRY_BASE_URL, {
    headers
  });
};
export const getAllStatesByCountry = async (country) => {
  return axios.post(COUNTRY_BASE_URL, country, {
    headers
  });
};

export const getAllCitiesInAState = async (data) => {
  return axios.post(CITY_BASE_URL, data, {
    headers
  });
};

export const getKudaAuthToken = async (data) => {
  const url = 'https://kuda-openapi.kuda.com/v2/Account/GetToken'
  return axios.post(url, data, {})
}

export const getAllBanks = async (data) => {
  const url = 'https://kuda-openapi.kuda.com/v2/'
  return axios.post(url, data, {
    headers: header
  })
}

export const getYsgRoutes = async () => {
  const url = "https://ws.ysgtransport.ng/api/v1/?ws=Routes Datalist"
  return axios.get(url, {headers:ysgHeaders})
}

export const getLatLong = async (data) => {
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${data.houseNumber}+${data.streetName}+${data.city},+${data.state},+${data.country}
  &key=${process.env.VUE_APP_GOOGLE_MAP_API_KEY}`;
  return axios.get(url);
};

export const getCountryCodes = async () => {
  const url = `https://restcountries.com/v3.1/all?fields=idd,flags,name`
  return axios.get(url, {
    headers: headers
  })
}

export const createPaymentLog = async (data) => {
  const url = process.env.VUE_APP_NPAY_BASE_URL+'/api/payment-log/create'
  return axios.post(url, data )
}
export const npayVerifyPaystackPayment = async (data) => {
  const url = process.env.VUE_APP_NPAY_BASE_URL+'/api/paystack/verify-payment'
  return axios.post(url, data )
}

export const getAllNpayBanks = async ()=>{
  const  url = process.env.VUE_APP_NPAY_BASE_URL+'/api/globus/get-all-banks-code'
  return axios.get(url)
}



export const customerOnBoarding = (data) => {
  const url = "/api/customer/onboard"
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
};
export const getCustomerByEmail = (data) =>{
  const url = `/api/customer/get-by-email`
  return callEndpoint({method:POST,url,data})
}
export const getCompanyData = async (companyId) => {
  const url = `/api/transport-company/get/${companyId}`;
  return callEndpoint({
    url,
    needsAuth: true
  });
};


export const getTransportCompanyTerminals = async () => {
  const url = `/api/aggregated-trips/get-all-operational-cities`;
  return callEndpoint({
    url
  });
};

export const getAllVehicleType = async () => {
  const url = `/api/hiring-criteria/get-all-vehicle-type`
  return callEndpoint({
    url
  })
}
export const getAllVehicleTypeBasedOnSearchQuery = async (data) => {
  const url = `/api/hiring-criteria/get-all-by-search-query-and-page`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export  const makeAHiringRequest = async (data)=>{
  const url = `/api/hiring/make-a-hire-request`
  return callEndpoint({method:POST, url,data})
}

export const getAllHiringCities = async ()=>{
  const url = `/api/hiring/get-all-hiring-cities`
  return callEndpoint({url})
}
export const getAllPendingTripsBySearchQueryAndPage = async (data) => {
  const url = `/api/aggregated-trips/pending-trips`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllPendingTripsByPage = async (data) => {
  const url = `/api/trip/get-all-pending-trips-by-page`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const cancelTrip = async (data) => {
  const url = `/api/trip/cancel-trip/${data.transportCompanyId}/${data.terminalId}/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}
export const getTripByTripId = async (data) => {
  const url = `/api/aggregated-trips/get-trip-by-schedule-id-or-trip-id`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const verifyPaystackPayment = async (userId, reference, data) => {
  const url = `/api/paystack/verify-payment/${userId}/${reference}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const addBookingCode = async (data) => {
  const url = `/api/booking/booking-code/add`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getPassengerDetail = async (bookingCode) => {
  const url = `/api/booking/booking-code/get-traveller-details-by-code/${bookingCode}`
  return callEndpoint({
    url
  })
}

export const verifyPassengerDetailExist = async (detail) => {
  const url = `/api/booking/booking-code/verify-traveller-details-exist/${detail}`
  return callEndpoint({
    url
  })
}
export const getBookingCodeByPhoneNumber = async (phoneNumber) => {
  const url = `/api/booking/booking-code/get-booking-code-by-phone-number/${phoneNumber}`
  return callEndpoint({
    url
  })
}

export const sendOtpToPassenger = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/send`
  return axios.post(url, data)
}

export const verifyPassengerOtp = async (data) => {
  const url = `https://api.ng.termii.com/api/sms/otp/verify`
  return axios.post(url, data)
}


export const addBooking = async (data) => {
  const url = `/api/booking/add-booking`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const reserveBooking = async (data)=>{
  const url = "/api/booking/reserve-booking"
  return callEndpoint({method:POST,url,data})
}
export const getBookingByPaymentReference = async (data) => {
  const url = `/api/booking/get-by-payment-reference`
  return callEndpoint({method:POST,url,data})
}

export const addScheduleBooking = async (data) => {
  const url = `/api/booking/add-schedule-booking`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getPaystackAccountDetail = async (email) => {
  const url = `/api/paystack/get-account-by-user-email/${email}`
  return callEndpoint({
    url
  })
}



export const addQuery = async (data) => {
  const url = `/api/query/add-query`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const subscribeToNewsLetter = async (data) => {
  const url = `/api/subscribers/subscribe`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllOneWayTrips = async (data) => {
  const url = `/api/customer/get-all-one-way-trip`
  return callEndpoint({
    url,
    data
  })
}

export const getCorporateAgentByApplicationUserId = async (id) => {
  const url = `/api/corporate-agent/get-by-user-id/${id}`
  return callEndpoint({
    url
  })
}

export const onboardCorporateAgent = async (data, id) => {
  const url = `/api/service-consumer/onboard/${id}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}


export const getMobileAgentByUserId = async (userId) => {
  const url = `/api/mobile-agent/get-by-user-id/${userId}`
  return callEndpoint({
    url
  })
}

export const onBoardMobileAgent = async (data) => {
  const url = `/api/mobile-agent/onboard`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getCorporateOrganizationOnboardingByEmail = async (companyEmail) => {
  const url = `/api/corporate-organization/get-organization-onboarding-by-email/${companyEmail}`
  return callEndpoint({
    url
  })
}

export const getPosProviderByApplicationUserEmail = async (data) => {
  const url = `/api/partner/pos-provider/get-pos-provider-by-email`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const unReserveSeats = async (data) => {
  const url = `/api/customer-trip/un-reserve-seat/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const reserveSeats = async (data) => {
  const url = `/api/customer-trip/reserve-seat/${data.tripId}`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const getUserSelectedDate = () => {
  return dayjs().format('YYYY-MM-DDTHH:mm:ss')
}


export const getWaterTransportOperatorByEmail = async (data) => {
  const url = `/api/water-transport-operator/get-by-operator-email`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getAllJettiesOfWaterTransportOperators = async () => {
  const url = `/api/water-transport-operator/wave-route/get-all-operational-jetties`
  return callEndpoint({
    url
  })
}

export const getAllPendingTripsOfWaterTransportOperators = async (data) => {
  const url = `/api/water-transport-operator/trip/get-all-pending-trips-by-search-query-and-page`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const getTripByIdAndWaterTransportOperatorId = async (data) => {
  const url = `/api/water-transport-operator/trip/get-by-id-and-water-transport-operator-id`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}
export const unReserveVesselSeats = async (data) => {
  const url = `/api/customer-trip/un-reserve-vessel-seat`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const reserveVesselSeats = async (data) => {
  const url = `/api/customer-trip/reserve-vessel-seat`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}
export const addWaveBooking = async (data) => {
  const url = `/api/water-transport-operator/booking/new-booking`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getInsuranceProviderByEmail = async (data) => {
  const url = `/api/insurance-provider/get-by-email`
  return callEndpoint({
    method: POST,
    url,
    data
  })
}

export const getActiveDiscount = async () =>{
  const url = `/api/promotion-bd/discount/active`
  return callEndpoint({url})
}

export const onBoardInsuranceProvider = async (data) => {
  const url = `/api/insurance-provider/on-boarding`
  return callEndpoint({
    method: PATCH,
    url,
    data
  })
}

export const getAllPlans = async () => {
  const url = `/api/insurance/get-all-plans`
  return callEndpoint({
    url
  })
}

export const getRandomInsuranceProvider = async ()=>{
  const url = `/api/insurance/get-random-provider-plans`
  return callEndpoint({url})
}

export const getAllActiveAdverts = async (data) => {
  const url = `/api/advert/get-all-active-adverts`
  return callEndpoint({method: POST, url, data})
}

export const dailyAdvertClick = async (data) =>{
  const url =  `/api/advert/daily-click`
  return callEndpoint({method:POST,url,data})
}

export const applyVoucher = async (data) => {
  const url = `/api/promotion/voucher/apply-voucher`
  return callEndpoint({method: POST, url, data})
}

// Train within service
export const getAllLagosTrainTerminals = async (data) => {
  const url = `/api/regulator/terminal/get-all`
  return callEndpoint({method: POST, url, data},process.env.VUE_APP_TRAIN_WITHIN_BASE_URL)
}

export const searchNrcTrainTrips = async (data) => {
  const url = `/api/regulator/trip/search`
  return callEndpoint({method: POST, url, data},process.env.VUE_APP_TRAIN_WITHIN_BASE_URL)
}

export const unReserveNrcTrainSeats = async (data) => {
  const url = `/api/regulator/booking/unreserved-seat`
  return callEndpoint({
    method: POST,
    url,
    data
  },process.env.VUE_APP_TRAIN_WITHIN_BASE_URL)
}

export const reserveNrcTrainSeats = async (data) => {
  const url = `/api/regulator/booking/reserve-seat`
  return callEndpoint({
    method: POST,
    url,
    data
  },process.env.VUE_APP_TRAIN_WITHIN_BASE_URL)
}
export const reserveNrcTrainBookings = async (data) => {
  const url = `/api/regulator/booking/reserve-booking`
  return callEndpoint({
    method: POST,
    url,
    data
  },process.env.VUE_APP_TRAIN_WITHIN_BASE_URL)
}

export const getNrcTrainBookingByPaymentReference = async (data) => {
  const url = `/api/regulator/booking/get-by-payment-reference`
  return callEndpoint({
    method: POST,
    url,
    data
  },process.env.VUE_APP_TRAIN_WITHIN_BASE_URL)
}