// import axios from "axios";
// import authHeader from "@/services/api/auth-header";
import METHODS from "@/services/api/apiMethods";
import VueJwtDecode from "vue-jwt-decode";
import axios from "axios";
import authHeader from "@/services/api/auth-header";
import callEndpoint from "@/services/api/index";

const {
  // GET,
  POST,
  // PUT,
  PATCH,
  // DELETE
} = METHODS;


const headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

const authServiceCallEndpoint = ({ method, url, data }) => {
  return axios[method](process.env.VUE_APP_AUTH_BASE_URL+url,data,{headers:headers})
};

export const login = async (jsonData) => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("userData");
  jsonData.client_id = process.env.VUE_APP_AUTH_BASE_LOGIN_CLIENT_ID;
  jsonData.grant_type = "password"

  const formData = encodeMapToFormUrlEncoded(jsonData)
  const url = "/realms/nomadicpod/protocol/openid-connect/token";
  const { data } = await authServiceCallEndpoint({
    method: POST,
    data: formData,
    url,
  });

  let decodedToken = VueJwtDecode.decode(data.access_token);
  const access_token = data.access_token;
  const base64Url = access_token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  );
  sessionStorage.setItem("userData", JSON.stringify(decodedToken));
  sessionStorage.setItem("userToken", JSON.stringify(data));

  return jsonPayload;
};

export const  getClientAuthToken = async ()=>{
  let request = {}
  request.client_id = process.env.VUE_APP_AUTH_BASE_CLIENT_ID;
  request.client_secret = process.env.VUE_APP_AUTH_BASE_CLIENT_SECRET;
  request.grant_type = "client_credentials";
  const formData = encodeMapToFormUrlEncoded(request)
  const url = "/realms/nomadicpod/protocol/openid-connect/token";
  const { data } = await authServiceCallEndpoint({
    method: POST,
    data: formData,
    url,
  });
  sessionStorage.setItem("userToken", JSON.stringify(data));
}

export const validUsername = async (params) =>{
  let userToken = sessionStorage.getItem("userToken");
  if(!userToken || true){
   await getClientAuthToken()
  }
  const url = "/admin/realms/nomadicpod/users"
  return await axios.get(process.env.VUE_APP_AUTH_BASE_URL+url,{params:params,headers:authHeader()})
}

function encodeMapToFormUrlEncoded(jsonObject) {
  const encodedPairs = [];
  for (const [key, value] of Object.entries(jsonObject)) {
    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value.toString());
    encodedPairs.push(`${encodedKey}=${encodedValue}`);
  }
  return encodedPairs.join('&');
}

export const logout = () => {
  sessionStorage.removeItem("userToken");
  sessionStorage.removeItem("userData");
  sessionStorage.removeItem("customerData");
  sessionStorage.clear()
  window.location.href = "/";
};

export const isTokenExpired =  (token) => {
  if (!token) return true;
  try {
    const decodedToken = VueJwtDecode.decode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  } catch (error) {
    return true;
  }
}

export const customerSignUp = (data) => {
  const url = "/api/customer/register";
  return callEndpoint({method: POST, url, data});
};


export const verifyEmail = (data) => {
  const url = "/auth/accounts/verify-registration/";
  return authServiceCallEndpoint({ method: POST, url, data });
};
export const resetPassword = (data) => {
  const url = "/api/customer/reset-password";
  return callEndpoint({ method: PATCH, url, data });
};

export const verifyByEmailOTP = (data)=>{
  const url = `/api/customer/verify-with-code`;
  return callEndpoint({method:PATCH,url,data})
};
export const resetNewPassword = (data, query) => {
  console.log(data);
  console.log(query);
  const url = `/api/user/mobile-new-password`;
  return callEndpoint({ method: POST, url, data });
};

